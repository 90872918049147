import React from 'react'
import { withRouter } from 'react-router'
import Countries from '../../../assets/data/countries'
import AccountTypes from '../../../assets/data/account_types'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Account Type</label>
                <select name="type" value={props.type} onChange={props.onChange}>
                    {
                        AccountTypes.map((type, key) => <option key={key} value={key}>{type}</option>)
                    }
                </select>
                <span className="error">{props.errors.type}</span>
            </div>
            {
                typeof props.username !== 'undefined' && <>
                    <div className="form-input">
                        <label>Username</label>
                        <input type="text" name="username" value={props.username} onChange={props.onChange} />
                        <span className="error">{props.errors.username}</span>
                    </div>
                    <div className="form-input">
                        <label>Password</label>
                        <input type="text" name="password" password={props.password} onChange={props.onChange} />
                        <span className="error">{props.errors.password}</span>
                    </div>
                </>
            }
            <div className="form-input">
                <label>Firstname</label>
                <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                <span className="error">{props.errors.firstname}</span>
            </div>
            <div className="form-input">
                <label>Lastname</label>
                <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                <span className="error">{props.errors.lastname}</span>
            </div>
            <div className="form-input">
                <label>Phone</label>
                <input type="phone" name="phone" value={props.phone} onChange={props.onChange} />
                <span className="error">{props.errors.phone}</span>
            </div>
            <div className="form-input">
                <label>Address</label>
                <input type="text" name="address" value={props.address} onChange={props.onChange} />
                <span className="error">{props.errors.address}</span>
            </div>
            <div className="form-input">
                <label>City</label>
                <input type="text" name="city" value={props.city} onChange={props.onChange} />
                <span className="error">{props.errors.city}</span>
            </div>
            <div className="form-input">
                <label>State</label>
                <input type="text" name="state" value={props.state} onChange={props.onChange} />
                <span className="error">{props.errors.state}</span>
            </div>
            <div className="form-input">
                <label>Country</label>
                <select name="country" value={props.country} onChange={props.onChange}>
                    <option value=""></option>
                    {
                        Countries.map((country, key) => <option key={key} value={country.name}>{country.name}</option>)
                    }
                </select>
                <span className="error">{props.errors.country}</span>
            </div>
            <div className="form-input">
                <label>Currency</label>
                <input type="text" name="currency" value={props.currency} onChange={props.onChange} />
                <span className="error">{props.errors.currency}</span>
            </div>
            
            <div className="form-input">
                <label>Transfer Charges</label>
                <input type="number" name="transferCharges" value={props.transferCharges} onChange={props.onChange} />
                <span className="error">{props.errors.transferCharges}</span>
            </div>
            <div className="form-input">
                <label>Minimum Balance</label>
                <input type="number" name="minimumBalance" value={props.minimumBalance} onChange={props.onChange} />
                <span className="error">{props.errors.minimumBalance}</span>
            </div>
            <div className="form-input">
                <label>Account Status</label>
                <select name="status" value={props.status} onChange={props.onChange}>
                    <option value=""></option>
                    <option value="1">Enabled</option>
                    <option value="2">Disabled</option>
                </select>
                <span className="error">{props.errors.status}</span>
            </div>
            {props.children}
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/accounts/all')}>Cancel</button>
                <button type="submit">Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)