import React from 'react'
import { withRouter } from 'react-router'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Type</label>
                <select name="type" defaultValue={props.type} onChange={props.onChange}>
                    <option></option>
                    <option value="1">Deposit</option>
                    <option value="2">Withdraw</option>
                </select>
                <span className="error">{props.errors.type}</span>
            </div>
            <div className="form-input">
                <label>Account (Username)</label>
                <input type="text" name="username" value={props.username} onChange={props.onChange} />
                <span className="error">{props.errors.username}</span>
            </div>
            <div className="form-input">
                <label>Amount</label>
                <input type="number" name="value" value={props.value} onChange={props.onChange} />
                <span className="error">{props.errors.value}</span>
            </div>
            <div className="form-input">
                <label>Description</label>
                <input type="text" name="description" value={props.description} onChange={props.onChange} />
                <span className="error">{props.errors.description}</span>
            </div>
            <div className="form-input">
                <label>Date</label>
                <input type="date" name="date" value={props.date} onChange={props.onChange} />
                <span className="error">{props.errors.date}</span>
            </div>
            <div className="form-input">
                <label>Time</label>
                <input type="time" name="time" value={props.time} onChange={props.onChange} />
                <span className="error">{props.errors.time}</span>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/transactions/all')}>Cancel</button>
                <button type="submit">Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)