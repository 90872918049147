import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import { connect } from 'react-redux'
import Filters from './filters.component'
import Pagination from '../../components/pagination/pagination.component'
import './accounts.styles.scss'
import Service from './accounts.service'
import iconNew from '../../assets/icons/new-light.svg'

class Accounts extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadAccounts()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadAccounts())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadAccounts()
    }

    onDelete = id => {
        let shouldDelete = window.confirm("Do you really want to perform this operation?");
        if(shouldDelete){
            this.service.deleteAccount(id)
        }
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="accounts">
                    <div className="header">
                        <h4>Accounts</h4>
                        <div className="buttons">
                            <Link className="btn" to="/accounts/create"><img alt="" src={iconNew} />New Account</Link>
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Username</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Currency</th>
                                <th>Balance</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.accounts.map((account, key) => <tr key={key}>
                                    <td>{account.number}</td>
                                    <td>{account.username}</td>
                                    <td>{account.firstname}</td>
                                    <td>{account.lastname}</td>
                                    <td>{account.currency}</td>
                                    <td>{account.summary.balance}</td>
                                    <td>{new Date(account.created * 1000).toString('dd MMM, yyyy')}</td>
                                    <td><button onClick={() => this.props.history.push('/accounts/' + account.id)}>Edit</button></td>
                                    <td><button onClick={() => this.onDelete(account.id)}>Delete</button></td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Accounts))