import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import {IsValid} from '../common.service'
import { ScrollIntoViewById } from '../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        saving: false,
        showAddTransferSetting: false,
        type: "",
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        currency: "",
        transferSettings: [],
        transferCharges: "",
        minimumBalance: "",
        status: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}



const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/accounts/create",
        data: {
            type: Number(instance.state.type),
            username: instance.state.username.trim().toLowerCase(),
            password: instance.state.password,
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            phone: instance.state.phone,
            address: instance.state.address,
            city: instance.state.city,
            state: instance.state.state,
            country: instance.state.country,
            currency: instance.state.currency,
            transferSettings: instance.state.transferSettings,
            transferCharges: instance.state.transferCharges,
            minimumBalance: instance.state.minimumBalance,
            status: instance.state.status
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: "Unknown Error"
                }
            })
            ScrollIntoViewById("create")
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Account Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
    ScrollIntoViewById("create")
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () =>  IsValid(instance),
        save: () => save(instance)
    }
}

export default Service