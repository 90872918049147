import React from 'react'
import { Switch, Route } from "react-router-dom"
import Login from './login/login.page'

const AuthController = () => {
    return (
        <div className="">
            <Switch>
                <Route path="/auth" component={Login} />
            </Switch>
        </div>
    )
}

export default AuthController