import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ASSET_ENDPOINT } from '../../../../config'
import './photo.styles.scss'
import Service from './photo.service'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class Photo extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onChange = (e) => {
        if (e.target.files.length === 0) {
            return
        }
        let data = new FormData()
        data.append('id', this.props.accountId)
        data.append('photo', e.target.files[0])
        this.service.save(data)
    }

    render() {
        return (
            <div className="photo-cont" id="photo">
                {(this.state.saving) && <Dialog><Loading /></Dialog>}
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                <div className="photo">
                    <img alt="" src={ASSET_ENDPOINT + '/photos/' + this.state.photo} />
                    <input type="file" name="file" accept=".jpg" onChange={this.onChange} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Photo))