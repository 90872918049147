import React from 'react'
import './transfer-settings.styles.scss'
import TransferSetting from './transfer-setting/transfer-setting.component'

const TransferSettings = props => {
    return (
        <div className="transfer-settings">
            {
                props.transferSettings.map((transferSetting, key) => <TransferSetting key={key} index={key} {...transferSetting} onRemove={() => props.onRemove(key)}/>)
            }
        </div>
    )
}

export default TransferSettings