import React from 'react'
import Filter from '../../components/filter/simple/simple-filter.component'

const Filters = props => {
    return (
        <Filter columns={columns} onChange={props.onChange} onSubmit={props.onSubmit} {...props.filter} />
    )
}

const columns = [
    {
        name: "username",
        label: "Username",
        type: "S"
    },
    {
        name: "firstname",
        label: "Firstname",
        type: "S"
    },
    {
        name: "lastname",
        label: "Lastname",
        type: "S"
    },
    {
        name: "phone",
        label: "Phone",
        type: "S"
    },
    {
        name: "country",
        label: "Country",
        type: "S"
    },
]

export default Filters