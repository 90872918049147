import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './create.service'
import './create.styles.scss'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Form from '../components/form.page'
import FormHelper from '../../../utils/FormHelper'
import TransferSettings from '../components/transfer-settings/transfer-settings.component'
import AddTransferSetting from '../components/add-transfer-setting/add-transfer-setting.component'

class Create extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    addToTransferSettings = transferSetting => {
        let transferSettings = this.state.transferSettings
        transferSettings.push(transferSetting)
        this.setState({
            ...this.state,
            transferSettings: transferSettings
        })
    }

    onRemoveTransferSetting = index => {
        let transferSettings = this.state.transferSettings.filter((transferSettings, key) => key !== index)
        this.setState({
            ...this.state,
            transferSettings: transferSettings
        })
    }

    closeAddTransferSetting = () => {
        this.setState({
            ...this.state,
            showAddTransferSetting: false
        })
    }

    onAddTransferSetting = () => {
        this.setState({
            ...this.state,
            showAddTransferSetting: true
        })
    }

    render() {
        return (
            <div id="create" className="create">
                {(this.state.saving) && <Dialog><Loading /></Dialog>}
                {this.state.showAddTransferSetting && <Dialog><AddTransferSetting onAddTransferSetting={this.addToTransferSettings} onCancel={this.closeAddTransferSetting} /></Dialog>}
                <div className="header">
                    <h4>Create Account</h4>
                </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} {...this.state} >
                    <div className="header">
                            <h4>Transfer Settings</h4>
                        </div>
                        <span className="btn" onClick={this.onAddTransferSetting}>Add Transfer Setting</span>
                        <TransferSettings transferSettings={this.state.transferSettings} onRemove={this.onRemoveTransferSetting} />
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Create))