import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import { ScrollIntoViewById } from '../../../../utils/UIHelper'

const getDefaultState = instance => {
    return {
        photo: instance.props.photo,
        saving: false,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const save = (instance, data) => {
    instance.setState({
        ...instance.state,
        saving: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/accounts/admin/photo/update",
        data: data
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: "Unknown Error"
                }
            })
            ScrollIntoViewById("edit")
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                photo: response.data.data.photo,
                flag: {
                    type: "success",
                    text: "Photo Saved"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
    ScrollIntoViewById("edit")
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        save: data => save(instance, data)
    }
}

export default Service