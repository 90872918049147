import React from 'react'
import { Switch, Route } from "react-router-dom"
import Accounts from './accounts.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const AccountController = () => {
    return (
        <div className="account">
            <Switch>
                <Route path="/accounts/create" component={Create} />
                <Route path="/accounts/all" component={Accounts} />
                <Route path="/accounts/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default AccountController