import React from "react";
import iconDelete from "../../../../../assets/icons/delete-black.svg";

const TransferSetting = (props) => {
  return (
    <div className="transfer-setting">
      <div>
        <p>Transfer Stop: {props.stop}</p>
        <p>Transfer Message: {props.message}</p>
        <p>Transfer Code: {props.code}</p>
        <p>Status: {props.used ? "Used" : "Unused"}</p>
      </div>
      <div>
        <img
          src={iconDelete}
          alt=""
          onClick={() => props.onRemove(props.index)}
        />
      </div>
    </div>
  );
};

export default TransferSetting;
