import { NumberValidationRule, StringValidationRule } from "../../../../data/validation/rules"
import Validation from "../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        creating: false,
        stop: "",
        message: "",
        code: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.stop, (error) => errors.stop = error, { min: { value: 1, error: "Invalid" }, max: { value: 99, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.message, (error) => errors.message = error, { min: { value: 2, error: "Too short" }, max: { value: 50, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.code, (error) => errors.code = error, { min: { value: 2, error: "Too short" }, max: { value: 20, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addTransferSetting = (instance) => {
    instance.props.onAddTransferSetting({
        stop: instance.state.stop,
        message: instance.state.message,
        code: instance.state.code,
        used: false
    })
    instance.props.onCancel()
}



const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addTransferSetting: () => addTransferSetting(instance)
    }
}

export default Worker