import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import { NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'
import Validation from '../../../data/validation/validation'

const getDefaultState = () => {
    return {
        saving: false,
        username: "",
        value: "",
        type: "",
        date: "",
        time: "",
        description: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.type, (error) => instance.state.errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" }  })
    validation.addValidationRule(StringValidationRule, instance.state.username, (error) => instance.state.errors.username = error, { min: { value: 6, error: "Invalid" }, max: { value: 20, error: "Invalid" }  })
    validation.addValidationRule(NumberValidationRule, instance.state.value, (error) => instance.state.errors.value = error, { min: { value: 1, error: "Invalid" }, max: { value: 900000000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => instance.state.errors.description = error, { min: { value: 5, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addDeposit = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/account/transactions/create",
        data: {
            accountUsername: instance.state.username.trim().toLowerCase(),
            value: instance.state.value,
            description: instance.state.description,
            currency: 1,
            type: Number(instance.state.type),
            date: instance.state.date,
            time: instance.state.time

        }
    }).then(response => handleAddDepositResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: "Unknown Error"
                }
            })
        })
}

const handleAddDepositResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Deposit Added"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () =>  isValid(instance),
        addDeposit: () => addDeposit(instance)
    }
}

export default Service