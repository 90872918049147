import React from 'react'
import { Switch, Route } from "react-router-dom"
import NewDeposit from './new-deposit/new-deposit.page'
import Transactions from './transactions.page'

const TransactionController = () => {
    return (
        <Switch>
            <Route path="/transactions/create" component={NewDeposit}  />
            <Route path="/transactions/all" component={Transactions} />
        </Switch>
    )
}

export default TransactionController